// src/pages/PaymentPage.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren
import './PaymentPage.css'; // Zusätzliche spezifische CSS-Datei
import { AuthContext } from '../contexts/AuthContext';

function PaymentPage() {
  const { isAuthenticated } = useContext(AuthContext);
  const [amount, setAmount] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  
  const handleSubmit = async e => {
    e.preventDefault();
    if (!isAuthenticated) {
      setAlertMessage('Bitte melde dich zuerst an.');
      setAlertType('error');
      return;
    }
    
    if (amount <= 0) {
      setAlertMessage('Bitte gib einen gültigen Betrag ein.');
      setAlertType('error');
      return;
    }
    
    try {
      const res = await axios.post('/api/payments', { amount });
      setAlertMessage(`Guthaben erfolgreich aufgeladen: ${amount} EUR`);
      setAlertType('success');
      setAmount('');
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Aufladen des Guthabens.');
      setAlertType('error');
    }
  };
  
  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };
  
  return (
    <div className="form-container">
      <h2>Guthaben aufladen</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="number" 
          name="amount" 
          placeholder="Betrag in EUR" 
          value={amount} 
          onChange={(e) => setAmount(e.target.value)} 
          required 
          min="1"
        />
        <button type="submit">Aufladen</button>
      </form>
      
      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default PaymentPage;
