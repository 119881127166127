// src/pages/SettingsPage.js

import React, { useState } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren
import './SettingsPage.css'; // Zusätzliche spezifische CSS-Datei

function SettingsPage() {
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    address: '',
  });
  
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  
  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    smsNotifications: false,
  });
  
  const [privacySettings, setPrivacySettings] = useState({
    profileVisibility: 'public', // Optionen: 'public', 'private', 'friends'
  });
  
  const [language, setLanguage] = useState('de'); // Beispiel: 'de', 'en'
  
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  
  const handleProfileChange = e => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };
  
  const handlePasswordChange = e => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };
  
  const handleNotificationChange = e => {
    setNotificationSettings({ ...notificationSettings, [e.target.name]: e.target.checked });
  };
  
  const handlePrivacyChange = e => {
    setPrivacySettings({ ...privacySettings, [e.target.name]: e.target.value });
  };
  
  const handleLanguageChange = e => {
    setLanguage(e.target.value);
  };
  
  const handleProfileSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.put('/api/users/profile', profileData);
      setAlertMessage('Profilinformationen erfolgreich aktualisiert.');
      setAlertType('success');
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Aktualisieren der Profilinformationen.');
      setAlertType('error');
    }
  };
  
  const handlePasswordSubmit = async e => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setAlertMessage('Die neuen Passwörter stimmen nicht überein.');
      setAlertType('error');
      return;
    }
    try {
      const res = await axios.put('/api/users/change-password', passwordData);
      setAlertMessage('Passwort erfolgreich geändert.');
      setAlertType('success');
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Ändern des Passworts.');
      setAlertType('error');
    }
  };
  
  const handleNotificationSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.put('/api/users/notifications', notificationSettings);
      setAlertMessage('Benachrichtigungseinstellungen erfolgreich aktualisiert.');
      setAlertType('success');
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Aktualisieren der Benachrichtigungseinstellungen.');
      setAlertType('error');
    }
  };
  
  const handlePrivacySubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.put('/api/users/privacy', privacySettings);
      setAlertMessage('Datenschutzeinstellungen erfolgreich aktualisiert.');
      setAlertType('success');
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Aktualisieren der Datenschutzeinstellungen.');
      setAlertType('error');
    }
  };
  
  const handleLanguageSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.put('/api/users/language', { language });
      setAlertMessage('Spracheinstellungen erfolgreich aktualisiert.');
      setAlertType('success');
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Aktualisieren der Spracheinstellungen.');
      setAlertType('error');
    }
  };
  
  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };
  
  return (
    <div className="form-container">
      <h2>Einstellungen</h2>
      
      {/* Profilinformationen aktualisieren */}
      <form onSubmit={handleProfileSubmit}>
        <h3>Profilinformationen</h3>
        <input 
          type="text" 
          name="name" 
          placeholder="Name" 
          value={profileData.name} 
          onChange={handleProfileChange} 
          required 
        />
        <input 
          type="email" 
          name="email" 
          placeholder="E-Mail" 
          value={profileData.email} 
          onChange={handleProfileChange} 
          required 
        />
        <input 
          type="text" 
          name="address" 
          placeholder="Adresse" 
          value={profileData.address} 
          onChange={handleProfileChange} 
          required 
        />
        <button type="submit">Aktualisieren</button>
      </form>
      
      {/* Passwort ändern */}
      <form onSubmit={handlePasswordSubmit}>
        <h3>Passwort ändern</h3>
        <input 
          type="password" 
          name="currentPassword" 
          placeholder="Aktuelles Passwort" 
          value={passwordData.currentPassword} 
          onChange={handlePasswordChange} 
          required 
        />
        <input 
          type="password" 
          name="newPassword" 
          placeholder="Neues Passwort" 
          value={passwordData.newPassword} 
          onChange={handlePasswordChange} 
          required 
        />
        <input 
          type="password" 
          name="confirmPassword" 
          placeholder="Passwort bestätigen" 
          value={passwordData.confirmPassword} 
          onChange={handlePasswordChange} 
          required 
        />
        <button type="submit">Passwort ändern</button>
      </form>
      
      {/* Benachrichtigungseinstellungen */}
      <form onSubmit={handleNotificationSubmit}>
        <h3>Benachrichtigungseinstellungen</h3>
        <label>
          <input 
            type="checkbox" 
            name="emailNotifications" 
            checked={notificationSettings.emailNotifications} 
            onChange={handleNotificationChange} 
          />
          E-Mail-Benachrichtigungen
        </label>
        <label>
          <input 
            type="checkbox" 
            name="smsNotifications" 
            checked={notificationSettings.smsNotifications} 
            onChange={handleNotificationChange} 
          />
          SMS-Benachrichtigungen
        </label>
        <button type="submit">Einstellungen speichern</button>
      </form>
      
      {/* Datenschutzeinstellungen */}
      <form onSubmit={handlePrivacySubmit}>
        <h3>Datenschutzeinstellungen</h3>
        <label>
          Sichtbarkeit des Profils:
          <select 
            name="profileVisibility" 
            value={privacySettings.profileVisibility} 
            onChange={handlePrivacyChange}
          >
            <option value="public">Öffentlich</option>
            <option value="private">Privat</option>
            <option value="friends">Freunde</option>
          </select>
        </label>
        <button type="submit">Einstellungen speichern</button>
      </form>
      
      {/* Spracheinstellungen */}
      <form onSubmit={handleLanguageSubmit}>
        <h3>Spracheinstellungen</h3>
        <label>
          Sprache:
          <select 
            name="language" 
            value={language} 
            onChange={handleLanguageChange}
          >
            <option value="de">Deutsch</option>
            <option value="en">Englisch</option>
            {/* Weitere Sprachen hinzufügen */}
          </select>
        </label>
        <button type="submit">Sprache ändern</button>
      </form>
      
      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default SettingsPage;
