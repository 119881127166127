// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  // Funktion zum Einrichten von Axios mit dem Token
  const setupAxios = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  useEffect(() => {
    // Überprüfe, ob ein Token im Local Storage vorhanden ist
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
      setupAxios(storedToken);
      // Benutzerinformationen abrufen
      axios.get('/api/auth/me')
        .then(response => setUser(response.data))
        .catch(err => {
          console.error('Fehler beim Abrufen der Benutzerinformationen:', err);
          logout();
        });
    }
  }, []);

  const register = async (credentials) => {
    try {
      const res = await axios.post('/api/auth/register', credentials);
      const receivedToken = res.data.token;
      localStorage.setItem('token', receivedToken);
      setToken(receivedToken);
      setIsAuthenticated(true);
      setupAxios(receivedToken);
      setUser(res.data.user);
    } catch (err) {
      console.error('Registrierungsfehler:', err);
      throw err;
    }
  };

  const login = async (credentials) => {
    try {
      const res = await axios.post('/api/auth/login', credentials);
      const receivedToken = res.data.token;
      localStorage.setItem('token', receivedToken);
      setToken(receivedToken);
      setIsAuthenticated(true);
      setupAxios(receivedToken);
      setUser(res.data.user);
    } catch (err) {
      console.error('Login-Fehler:', err);
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setIsAuthenticated(false);
    setUser(null);
    setupAxios(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, user, register, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
