// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import CreateTaskPage from './pages/CreateTaskPage';
import PaymentPage from './pages/PaymentPage';
import SubscriptionPage from './pages/SubscriptionPage';
import OfferRidePage from './pages/OfferRidePage';
import BookRidePage from './pages/BookRidePage';
import SearchServicesPage from './pages/SearchServicesPage';
import RegionalProductsPage from './pages/RegionalProductsPage';
import AddRegionalProductPage from './pages/AddRegionalProductPage';
import EditRegionalProductPage from './pages/EditRegionalProductPage';
import ProfilePage from './pages/ProfilePage';
import ManageCategoriesPage from './pages/ManageCategoriesPage';
import SettingsPage from './pages/SettingsPage';
import { AuthProvider } from './contexts/AuthContext';
import './styles/global.css'; // Globale Stile zuerst importieren

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <div className="container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/create-task" element={<CreateTaskPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/offer-ride" element={<OfferRidePage />} />
            <Route path="/book-ride" element={<BookRidePage />} />
            <Route path="/tasks" element={<SearchServicesPage />} />
            <Route path="/regional-products" element={<RegionalProductsPage />} />
            <Route path="/regional-products/add" element={<AddRegionalProductPage />} />
            <Route path="/regional-products/edit/:id" element={<EditRegionalProductPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/manage-categories" element={<ManageCategoriesPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            {/* Weitere Routen hinzufügen */}
          </Routes>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
