// src/components/Navbar.js

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Stelle sicher, dass die CSS-Datei vorhanden ist
import { AuthContext } from '../contexts/AuthContext';
import { FaUser, FaCog, FaBook, FaCreditCard, FaRocket, FaCar, FaSignOutAlt, FaBoxOpen, FaPlus, FaEdit } from 'react-icons/fa';

function Navbar() {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    logout();
    window.location.href = '/';
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest('.profile-dropdown')) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <nav className="navbar">
      <h1 className="navbar-logo">TimeSwap</h1>
      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        {!isAuthenticated && <li><Link to="/register">Registrieren</Link></li>}
        {!isAuthenticated && <li><Link to="/login">Login</Link></li>}
        {isAuthenticated && <li><Link to="/tasks">Dienstleistungen suchen</Link></li>}
        {isAuthenticated && <li><Link to="/regional-products"><FaBoxOpen /> Produkte finden</Link></li>}
        {isAuthenticated && <li className="profile-dropdown">
          <button onClick={toggleDropdown} className="profile-button"><FaUser /> Profil ▼</button>
          {isDropdownOpen && (
            <ul className="dropdown-menu">
              <li><Link to="/profile"><FaUser /> Mein Profil</Link></li>
              <li><Link to="/settings"><FaCog /> Einstellungen</Link></li>
              <li><Link to="/create-task"><FaBook /> Dienstleistung erstellen</Link></li>
              <li><Link to="/payment"><FaCreditCard /> Guthaben aufladen</Link></li>
              <li><Link to="/subscription"><FaRocket /> Abonnement</Link></li>
              <li><Link to="/offer-ride"><FaCar /> Fahrt anbieten</Link></li>
              <li><Link to="/book-ride"><FaCar /> Fahrt buchen</Link></li>
              <li><Link to="/regional-products/add"><FaPlus /> Produkte hinzufügen</Link></li>
              <li><Link to="/regional-products/edit"><FaEdit /> Produkte bearbeiten</Link></li>
              <li><button onClick={handleLogout} className="dropdown-button"><FaSignOutAlt /> Logout</button></li>
            </ul>
          )}
        </li>}
      </ul>
    </nav>
  );
}

export default Navbar;
