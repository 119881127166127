// src/pages/RegisterPage.js

import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Alert from '../components/Alert';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css'; // Beispiel-CSS

function RegisterPage() {
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setAlertMessage('Passwörter stimmen nicht überein.');
      setAlertType('error');
      return;
    }

    try {
      await register({
        name: formData.name,
        email: formData.email,
        password: formData.password,
      });
      setAlertMessage('Registrierung erfolgreich! Weiterleitung...');
      setAlertType('success');
      // Weiterleitung nach kurzer Verzögerung
      setTimeout(() => {
        navigate('/'); // Passe den Pfad nach Bedarf an
      }, 2000);
    } catch (err) {
      setAlertMessage(err.response?.data?.message || 'Registrierung fehlgeschlagen.');
      setAlertType('error');
    }
  };

  return (
    <div className="form-container">
      <h2>Registrieren</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          E-Mail:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Passwort:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            minLength="6"
          />
        </label>
        <label>
          Passwort bestätigen:
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            minLength="6"
          />
        </label>
        <button type="submit">Registrieren</button>
      </form>

      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={() => { setAlertMessage(''); setAlertType(''); }} 
      />
    </div>
  );
}

export default RegisterPage;
