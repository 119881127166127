// src/pages/SearchServicesPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import './SearchServicesPage.css'; // Spezifische CSS-Datei
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function SearchServicesPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [radius, setRadius] = useState(5); // Radius in Kilometern
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [center, setCenter] = useState({ latitude: 52.5200, longitude: 13.4050 }); // Beispiel: Berlin
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  
  useEffect(() => {
    // Kategorien vom Backend abrufen
    const fetchCategories = async () => {
      try {
        const res = await axios.get('/api/categories');
        setCategories(res.data);
      } catch (err) {
        console.error('Fehler beim Abrufen der Kategorien:', err);
        setAlertMessage('Fehler beim Laden der Kategorien.');
        setAlertType('error');
      }
    };
    
    // Standort des Benutzers ermitteln
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          setCenter({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        }, () => {
          console.warn('Standortzugriff verweigert, Standardstandort verwendet.');
        });
      }
    };
    
    fetchCategories();
    fetchUserLocation();
  }, []);
  
  useEffect(() => {
    // Dienstleistungen basierend auf den aktuellen Suchparametern laden
    fetchServices();
  }, [searchTerm, selectedCategory, radius, center]);
  
  const fetchServices = async () => {
    try {
      const res = await axios.get('/api/services', {
        params: {
          search: searchTerm,
          category: selectedCategory,
          longitude: center.longitude,
          latitude: center.latitude,
          radius: radius,
        },
      });
      setServices(res.data);
    } catch (err) {
      console.error('Fehler beim Abrufen der Dienstleistungen:', err);
      setAlertMessage('Fehler beim Laden der Dienstleistungen.');
      setAlertType('error');
    }
  };
  
  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };
  
  const handleCategoryChange = e => {
    setSelectedCategory(e.target.value);
  };
  
  const handleRadiusChange = e => {
    setRadius(e.target.value);
  };
  
  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };
  
  return (
    <div className="search-services-container">
      <h2>Dienstleistungen suchen</h2>
      <div className="search-filters">
        <form className="search-form" onSubmit={(e) => e.preventDefault()}>
          <input 
            type="text" 
            name="searchTerm" 
            placeholder="Suchbegriff" 
            value={searchTerm} 
            onChange={handleSearchChange} 
          />
          <select 
            name="category" 
            value={selectedCategory} 
            onChange={handleCategoryChange}
          >
            <option value="">Alle Kategorien</option>
            {categories.map(cat => (
              <option key={cat._id} value={cat._id}>{cat.name}</option>
            ))}
          </select>
          <input 
            type="number" 
            name="radius" 
            placeholder="Radius (km)" 
            value={radius} 
            onChange={handleRadiusChange} 
            min="1"
          />
        </form>
        
        <MapComponent center={center} setCenter={setCenter} />
      </div>
      
      <div className="services-list">
        {services.length > 0 ? (
          services.map(service => (
            <div key={service._id} className="service-item">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <p>Preis: {service.price} EUR</p>
              <p>Kategorie: {service.category.name}</p>
              {/* Weitere Details */}
            </div>
          ))
        ) : (
          <p>Keine Dienstleistungen gefunden.</p>
        )}
      </div>
      
      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

function MapComponent({ center, setCenter }) {
  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setCenter({
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        });
      },
    });
    return null;
  };
  
  return (
    <MapContainer center={[center.latitude, center.longitude]} zoom={13} style={{ height: '300px', width: '100%', marginTop: '20px' }}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker />
      <Marker position={[center.latitude, center.longitude]} />
    </MapContainer>
  );
}

export default SearchServicesPage;
