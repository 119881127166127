import React from 'react';
import './Alert.css';

function Alert({ message, type, onClose }) {
  if (!message) return null;

  return (
    <div className={`alert ${type}`}>
      <span>{message}</span>
      <button onClick={onClose} className="close-button">&times;</button>
    </div>
  );
}

export default Alert;
