import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import './ProfilePage.css';

function ProfilePage() {
  const [profile, setProfile] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await axios.get('/api/auth/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setProfile(res.data);
      } catch(err) {
        console.error('Fehler beim Abrufen des Profils:', err);
        setAlertMessage('Fehler beim Abrufen des Profils.');
        setAlertType('error');
      }
    };
    fetchProfile();
  }, []);

  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };

  if (!profile) return <p>Lade Profil...</p>;

  return (
    <div className="profile-container">
      <h2>Dein Profil</h2>
      <p><strong>Name:</strong> {profile.name}</p>
      <p><strong>E-Mail:</strong> {profile.email}</p>
      <p><strong>Adresse:</strong> {profile.address}</p>
      <p><strong>Abonnement:</strong> {profile.subscription}</p>
      <p><strong>Guthaben:</strong> {profile.balance}€</p>
      <p><strong>Verfügbar für Fahrten:</strong> {profile.isAvailableForRides ? 'Ja' : 'Nein'}</p>
      {/* Weitere Profilinformationen und Optionen zum Bearbeiten */}
      
      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default ProfilePage;
