// src/pages/RegionalProductsPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import { Link } from 'react-router-dom';
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren
import './RegionalProductsPage.css'; // Zusätzliche spezifische CSS-Datei
import { AuthContext } from '../contexts/AuthContext';

function RegionalProductsPage() {
  const { isAuthenticated } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const res = await axios.get('/api/regional-products');
      setProducts(res.data);
    } catch (err) {
      console.error('Fehler beim Abrufen der regionalen Produkte:', err);
      setAlertMessage('Fehler beim Laden der regionalen Produkte.');
      setAlertType('error');
    }
  };

  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };

  return (
    <div className="regional-products-container">
      <h2>Regionale Produkte finden</h2>

      {isAuthenticated && (
        <div className="add-product-button">
          <Link to="/regional-products/add" className="btn-primary">Produkt hinzufügen</Link>
        </div>
      )}

      <div className="products-list">
        {products.length > 0 ? (
          products.map(product => (
            <div key={product._id} className="product-item">
              <h3>{product.name}</h3>
              <p>{product.description}</p>
              <p>Preis: {product.price} EUR</p>
              <p>Region: {product.region}</p>
              {isAuthenticated && (
                <div className="product-actions">
                  <Link to={`/regional-products/edit/${product._id}`} className="btn-secondary">Bearbeiten</Link>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>Keine regionalen Produkte gefunden.</p>
        )}
      </div>

      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default RegionalProductsPage;
