import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import { AuthContext } from '../contexts/AuthContext';
import './ManageCategoriesPage.css';

function ManageCategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // Kategorien vom Backend abrufen
    const fetchCategories = async () => {
      try {
        const res = await axios.get('/api/categories');
        setCategories(res.data);
      } catch (err) {
        console.error('Fehler beim Abrufen der Kategorien:', err);
        setAlertMessage('Fehler beim Laden der Kategorien.');
        setAlertType('error');
      }
    };

    fetchCategories();
  }, []);

  const handleAddCategory = async e => {
    e.preventDefault();
    if (!newCategory.trim()) {
      setAlertMessage('Kategorie-Name darf nicht leer sein.');
      setAlertType('error');
      return;
    }

    try {
      const res = await axios.post('/api/categories', { name: newCategory });
      setCategories([...categories, res.data]);
      setNewCategory('');
      setAlertMessage('Kategorie erfolgreich hinzugefügt.');
      setAlertType('success');
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Hinzufügen der Kategorie.');
      setAlertType('error');
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      // Implementiere die Löschfunktion, falls erforderlich
      // Beispiel:
      // await axios.delete(`/api/categories/${id}`);
      // setCategories(categories.filter(cat => cat._id !== id));
      setAlertMessage('Löschen ist derzeit nicht implementiert.');
      setAlertType('info');
    } catch (err) {
      setAlertMessage('Fehler beim Löschen der Kategorie.');
      setAlertType('error');
    }
  };

  if (!isAuthenticated) {
    return <p>Du bist nicht berechtigt, diese Seite zu sehen.</p>;
  }

  return (
    <div className="manage-categories-container">
      <h2>Kategorien verwalten</h2>
      <form onSubmit={handleAddCategory}>
        <input
          type="text"
          name="newCategory"
          placeholder="Neue Kategorie"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          required
        />
        <button type="submit">Hinzufügen</button>
      </form>
      
      <ul className="categories-list">
        {categories.map(cat => (
          <li key={cat._id}>
            {cat.name}
            <button onClick={() => handleDeleteCategory(cat._id)}>Löschen</button>
          </li>
        ))}
      </ul>
      
      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={() => { setAlertMessage(''); setAlertType(''); }} 
      />
    </div>
  );
}

export default ManageCategoriesPage;
