// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/global.css'; // Importiere die globale CSS-Datei
import setupAxios from './axiosConfig';
import { AuthProvider, AuthContext } from './contexts/AuthContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <AuthContext.Consumer>
        {({ token }) => {
          setupAxios(token);
          return <App />;
        }}
      </AuthContext.Consumer>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
