import React, { useState, useContext } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import { AuthContext } from '../contexts/AuthContext';
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren

function OfferRidePage() {
  const { isAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    startLocation: '',
    endLocation: '',
    date: '',
    time: '',
    seats: '',
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!isAuthenticated) {
      setAlertMessage('Bitte melde dich zuerst an.');
      setAlertType('error');
      return;
    }

    try {
      const res = await axios.post('/api/rides', formData);
      setAlertMessage('Fahrt erfolgreich angeboten.');
      setAlertType('success');
      // Weitere Aktionen nach erfolgreichem Anbieten
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Anbieten der Fahrt.');
      setAlertType('error');
    }
  };

  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };

  return (
    <div className="form-container">
      <h2>Fahrt anbieten</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="startLocation" 
          placeholder="Startort" 
          value={formData.startLocation} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="text" 
          name="endLocation" 
          placeholder="Zielort" 
          value={formData.endLocation} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="date" 
          name="date" 
          value={formData.date} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="time" 
          name="time" 
          value={formData.time} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="number" 
          name="seats" 
          placeholder="Anzahl der Sitzplätze" 
          value={formData.seats} 
          onChange={handleChange} 
          required 
        />
        <button type="submit">Fahrt anbieten</button>
      </form>
      
      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default OfferRidePage;
