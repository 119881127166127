// src/pages/AddRegionalProductPage.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import { useNavigate } from 'react-router-dom';
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren
import './AddRegionalProductPage.css'; // Zusätzliche spezifische CSS-Datei
import { AuthContext } from '../contexts/AuthContext';

function AddRegionalProductPage() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    region: '',
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  if (!isAuthenticated) {
    return <p>Du bist nicht berechtigt, diese Seite zu sehen.</p>;
  }

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/regional-products', formData);
      setAlertMessage('Produkt erfolgreich hinzugefügt.');
      setAlertType('success');
      // Weiterleitung nach kurzer Verzögerung
      setTimeout(() => {
        navigate('/regional-products');
      }, 2000);
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Hinzufügen des Produkts.');
      setAlertType('error');
    }
  };

  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };

  return (
    <div className="form-container">
      <h2>Neues Produkt hinzufügen</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Produktname:
          <input 
            type="text" 
            name="name" 
            placeholder="Produktname" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
        </label>
        <label>
          Beschreibung:
          <textarea 
            name="description" 
            placeholder="Beschreibung" 
            value={formData.description} 
            onChange={handleChange} 
          />
        </label>
        <label>
          Preis (EUR):
          <input 
            type="number" 
            name="price" 
            placeholder="Preis (EUR)" 
            value={formData.price} 
            onChange={handleChange} 
            required 
            min="0.01"
            step="0.01"
          />
        </label>
        <label>
          Region:
          <input 
            type="text" 
            name="region" 
            placeholder="Region" 
            value={formData.region} 
            onChange={handleChange} 
            required 
          />
        </label>
        <button type="submit">Produkt hinzufügen</button>
      </form>

      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default AddRegionalProductPage;
