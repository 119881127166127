import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Erstelle diese CSS-Datei für Styling

function HomePage() {
  return (
    <div className="home-container">
      <section className="hero-section">
        <h1>Willkommen bei TimeSwap</h1>
        <p>
          TimeSwap ist die innovative Plattform, die es dir ermöglicht, deine Fähigkeiten und Dienstleistungen
          gegen Zeit zu tauschen. Egal, ob du Nachhilfe geben, Gartenarbeit erledigen oder IT-Dienstleistungen anbieten möchtest –
          mit TimeSwap findest du Gleichgesinnte, die bereit sind, ihre Zeit mit dir zu teilen.
        </p>
        <div className="hero-buttons">
          <Link to="/tasks" className="hero-button">Dienstleistung durchsuchen</Link>
          <Link to="/create-task" className="hero-button primary">Dienstleistung erstellen</Link>
        </div>
      </section>
      
      <section className="features-section">
        <div className="feature">
          <h3>Vielseitige Kategorien</h3>
          <p>
            Wähle aus einer Vielzahl von Kategorien, um genau die Dienstleistungen zu finden, die du benötigst oder anbieten möchtest.
          </p>
        </div>
        <div className="feature">
          <h3>Einfaches Matching</h3>
          <p>
            Unser intelligenter Matching-Algorithmus verbindet dich mit den richtigen Personen, um einen reibungslosen Tausch zu gewährleisten.
          </p>
        </div>
        <div className="feature">
          <h3>Sicher und Vertrauenswürdig</h3>
          <p>
            Mit unseren Sicherheitsmaßnahmen und der Community-Bewertung kannst du sicher sein, dass dein Tausch fair und zuverlässig abläuft.
          </p>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
