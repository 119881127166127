// src/pages/EditRegionalProductPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren
import './EditRegionalProductPage.css'; // Zusätzliche spezifische CSS-Datei
import { AuthContext } from '../contexts/AuthContext';

function EditRegionalProductPage() {
  const { isAuthenticated } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    region: '',
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      fetchProduct();
    }
  }, [isAuthenticated]);

  const fetchProduct = async () => {
    try {
      const res = await axios.get(`/api/regional-products/${id}`);
      setFormData({
        name: res.data.name,
        description: res.data.description,
        price: res.data.price,
        region: res.data.region,
      });
    } catch (err) {
      console.error('Fehler beim Abrufen des Produkts:', err);
      setAlertMessage('Fehler beim Laden des Produkts.');
      setAlertType('error');
    }
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.put(`/api/regional-products/${id}`, formData);
      setAlertMessage('Produkt erfolgreich aktualisiert.');
      setAlertType('success');
      // Weiterleitung nach kurzer Verzögerung
      setTimeout(() => {
        navigate('/regional-products');
      }, 2000);
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Aktualisieren des Produkts.');
      setAlertType('error');
    }
  };

  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };

  if (!isAuthenticated) {
    return <p>Du bist nicht berechtigt, diese Seite zu sehen.</p>;
  }

  return (
    <div className="form-container">
      <h2>Produkt bearbeiten</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="name" 
          placeholder="Produktname" 
          value={formData.name} 
          onChange={handleChange} 
          required 
        />
        <textarea 
          name="description" 
          placeholder="Beschreibung" 
          value={formData.description} 
          onChange={handleChange} 
        />
        <input 
          type="number" 
          name="price" 
          placeholder="Preis (EUR)" 
          value={formData.price} 
          onChange={handleChange} 
          required 
          min="0.01"
          step="0.01"
        />
        <input 
          type="text" 
          name="region" 
          placeholder="Region" 
          value={formData.region} 
          onChange={handleChange} 
          required 
        />
        <button type="submit">Produkt aktualisieren</button>
      </form>

      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default EditRegionalProductPage;
