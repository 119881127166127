import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';

function BookRidePage() {
  const [rides, setRides] = useState([]);
  const [selectedRide, setSelectedRide] = useState(null);
  const [offerPrice, setOfferPrice] = useState('');
  const [offerMessage, setOfferMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    const fetchRideRequests = async () => {
      try {
        const res = await axios.get('/api/rides/requests', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setRides(res.data);
      } catch(err) {
        console.error('Fehler beim Abrufen der Fahrtanfragen:', err);
        setAlertMessage('Fehler beim Abrufen der Fahrtanfragen.');
        setAlertType('error');
      }
    };
    fetchRideRequests();
  }, []);

  const handleOffer = async (rideId) => {
    if (!offerPrice || !offerMessage) {
      setAlertMessage('Bitte Preis und Nachricht angeben.');
      setAlertType('error');
      return;
    }

    try {
      const res = await axios.post('/api/rides/offers', {
        rideId,
        price: offerPrice,
        message: offerMessage,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setAlertMessage('Fahrtenangebot erfolgreich abgegeben.');
      setAlertType('success');
      // Aktualisiere die Liste der Fahrten
      setRides(rides.filter(ride => ride._id !== rideId));
      setOfferPrice('');
      setOfferMessage('');
    } catch(err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Abgeben des Fahrtenangebots.');
      setAlertType('error');
    }
  };

  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };

  return (
    <div className="book-ride-container">
      <h2>Fahrt buchen</h2>
      {rides.length === 0 ? (
        <p>Keine verfügbaren Fahrtanfragen.</p>
      ) : (
        <ul>
          {rides.map(ride => (
            <li key={ride._id}>
              <h3>Von: {ride.origin.address} nach {ride.destination.address}</h3>
              <p>Benutzer: {ride.requester.name}</p>
              <button onClick={() => handleOffer(ride._id)}>Angebot abgeben</button>
            </li>
          ))}
        </ul>
      )}

      <div className="offer-form">
        {selectedRide && (
          <form onSubmit={(e) => { e.preventDefault(); handleOffer(selectedRide); }}>
            <h3>Angebot für Fahrt</h3>
            <input 
              type="number" 
              placeholder="Preis in Euro" 
              value={offerPrice} 
              onChange={e => setOfferPrice(e.target.value)} 
              required 
              min="1"
            />
            <textarea 
              placeholder="Nachricht" 
              value={offerMessage} 
              onChange={e => setOfferMessage(e.target.value)} 
              required 
            />
            <button type="submit">Angebot abgeben</button>
          </form>
        )}
      </div>

      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default BookRidePage;
