// src/pages/CreateTaskPage.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import Alert from '../components/Alert';

function CreateTaskPage() {
  const { isAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    category: '',
    longitude: '',
    latitude: '',
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setAlertMessage('Bitte melde dich zuerst an.');
      setAlertType('error');
      return;
    }

    try {
      const res = await axios.post('/api/services', formData);
      setAlertMessage('Dienstleistung erfolgreich erstellt.');
      setAlertType('success');
      setFormData({
        title: '',
        description: '',
        price: '',
        category: '',
        longitude: '',
        latitude: '',
      });
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Erstellen der Dienstleistung.');
      setAlertType('error');
    }
  };

  return (
    <div className="form-container">
      <h2>Dienstleistung erstellen</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Titel:
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Beschreibung:
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </label>
        <label>
          Preis:
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
            min="0.01"
            step="0.01"
          />
        </label>
        <label>
          Kategorie:
          <select name="category" value={formData.category} onChange={handleChange} required>
            <option value="">Wähle eine Kategorie</option>
            {/* Dynamisch Kategorien hinzufügen */}
          </select>
        </label>
        <label>
          Longitude:
          <input
            type="number"
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
            required
            step="0.000001"
          />
        </label>
        <label>
          Latitude:
          <input
            type="number"
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
            required
            step="0.000001"
          />
        </label>
        <button type="submit">Dienstleistung erstellen</button>
      </form>

      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={() => { setAlertMessage(''); setAlertType(''); }} 
      />
    </div>
  );
}

export default CreateTaskPage;
