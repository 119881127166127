// src/pages/LoginPage.js

import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Alert from '../components/Alert';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; // Beispiel-CSS

function LoginPage() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(formData);
      setAlertMessage('Erfolgreich eingeloggt! Weiterleitung...');
      setAlertType('success');
      // Weiterleitung nach kurzer Verzögerung
      setTimeout(() => {
        navigate('/'); // Passe den Pfad nach Bedarf an
      }, 2000);
    } catch (err) {
      setAlertMessage(err.response?.data?.message || 'Login fehlgeschlagen.');
      setAlertType('error');
    }
  };

  return (
    <div className="form-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <label>
          E-Mail:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Passwort:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Einloggen</button>
      </form>

      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={() => { setAlertMessage(''); setAlertType(''); }} 
      />
    </div>
  );
}

export default LoginPage;
