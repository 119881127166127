// src/pages/SubscriptionPage.js

import React, { useState } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import '../styles/Form.css'; // Gemeinsame CSS-Datei importieren
import './SubscriptionPage.css'; // Zusätzliche spezifische CSS-Datei

function SubscriptionPage() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [duration, setDuration] = useState(1); // Laufzeit in Monaten
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  
  const subscriptionPlans = [
    {
      id: 'basic',
      name: 'Basic Abo',
      description: 'Ein grundlegendes Abonnement für den Zugang zu den Basisfunktionen von TimeSwap.',
      pricePerMonth: 5, // Beispielpreis in EUR
    },
    {
      id: 'premium',
      name: 'Premium Abo',
      description: 'Ein erweitertes Abonnement mit zusätzlichen Funktionen und Vorteilen.',
      pricePerMonth: 10, // Beispielpreis in EUR
    },
  ];
  
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setDuration(1); // Reset der Laufzeit
  };
  
  const handleDurationChange = (e) => {
    setDuration(parseInt(e.target.value));
  };
  
  const handleSubscribe = async () => {
    if (!selectedPlan) {
      setAlertMessage('Bitte wähle ein Abonnement aus.');
      setAlertType('error');
      return;
    }
    
    try {
      const res = await axios.post('/api/subscriptions', {
        planId: selectedPlan.id,
        duration, // Laufzeit in Monaten
      });
      setAlertMessage(`Abonnement erfolgreich für ${duration} Monat(e) gebucht.`);
      setAlertType('success');
      // Weitere Aktionen nach erfolgreichem Buchen
    } catch (err) {
      setAlertMessage(err.response.data.message || 'Fehler beim Buchen des Abonnements.');
      setAlertType('error');
    }
  };
  
  const closeAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };
  
  return (
    <div className="subscription-container">
      <h2>Abonnements</h2>
      <div className="subscription-plans">
        {subscriptionPlans.map(plan => (
          <div 
            key={plan.id} 
            className={`subscription-plan ${selectedPlan?.id === plan.id ? 'selected' : ''}`}
            onClick={() => handlePlanSelect(plan)}
          >
            <h3>{plan.name}</h3>
            <p>{plan.description}</p>
            <p><strong>{plan.pricePerMonth} EUR</strong> pro Monat</p>
            {selectedPlan?.id === plan.id && (
              <div className="subscription-actions">
                <label>
                  Laufzeit (Monate):
                  <input 
                    type="number" 
                    min="1" 
                    value={duration} 
                    onChange={handleDurationChange} 
                  />
                </label>
                <button onClick={handleSubscribe}>Dieses Abo buchen</button>
              </div>
            )}
          </div>
        ))}
      </div>
      
      {/* Alert-Komponente */}
      <Alert 
        message={alertMessage} 
        type={alertType} 
        onClose={closeAlert} 
      />
    </div>
  );
}

export default SubscriptionPage;
